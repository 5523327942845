import { ScenarioService } from './scenario.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Scenario } from './scenario.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { ClipboardService } from 'ngx-clipboard';
import { isDefined } from '@angular/compiler/src/util';

@Component({
    selector: 'app-scenario',
    templateUrl: './scenario.component.html',
    styleUrls: ['./scenario.component.scss']
})

export class ScenarioComponent implements OnInit {

    selectedScenario: Scenario = null;
    baseURL = `https://t-mobile_realplay.ce05.humanify.com/sessions/new-session?scenarioId=`;
    defaultURL = `https://t-mobile_realplay.ce05.humanify.com`;
    //baseURL = `https://t-mobile_realplay.ce05.humanify.com/client/tmobile/chat/`;
    //  defaultURL = `https://t-mobile_realplay.ce05.humanify.com`;
    rpURL;
    rsURL;
    rpURLsafe;
    isTokenCopied = false;
    generatedToken = '';

    constructor(private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private clipboardService: ClipboardService,
        private scenarioService: ScenarioService) {

        if (window.addEventListener) {
            window.addEventListener('message', this.receiveMessage.bind(this), false);
        }
    }

    ngOnInit(): void {
        this.scenarioService.getScenarios();
        this.scenarioService.scenarios.subscribe(data => {
            const scenarioId = this.route.snapshot.paramMap.get('id');
            if (scenarioId && data) {
                [this.selectedScenario] = data.filter(c => c.botScenarioId == scenarioId);

                // load the correct scenario based on bot scenario ID here:

                var embed_url = null;
                var embed_domain = null;

                this.route.queryParams
                .subscribe(params => {
                      embed_url = params.embed_url;
                      embed_domain = params.embed_domain;
                });
                if(isDefined(embed_url) && isDefined(embed_domain)){
                  embed_url = decodeURIComponent(embed_url);
                  embed_domain = decodeURIComponent(embed_domain);
                  this.defaultURL = embed_domain;
                }
                if (this.selectedScenario) {
                    this.rpURL = `${this.baseURL}${this.selectedScenario.botScenarioId}&autostart=text`;
                    this.rsURL = `${this.baseURL}${this.selectedScenario.botScenarioId}`;

                    if(isDefined(embed_url) && isDefined(embed_domain)){
                      this.rpURL = this.rsURL = embed_url;
                    }

                    this.rpURLsafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.rpURL);
                } else {
                   window.location.href = this.defaultURL;
                }
            }
        });


    }


    receiveMessage(e): void {

        console.log(e);

        if (e && e.data) {
            const result = JSON.parse(JSON.stringify(e.data));

            if (result && result.key) {
              console.log("result:", result)
              if(result.returnTo) {
                  var returnUrl = result.returnTo;
                  console.log("return Url", returnUrl);

                window.open(returnUrl, '_self').close();
              }else {
                window.open(this.rsURL, '_self').close();
              }

            }
        }

    }

    copyToken(): void {

        this.generatedToken = this.scenarioService.generateToken();

        if (this.generatedToken) {
            this.clipboardService.copyFromContent(this.generatedToken);
            this.isTokenCopied = true;
            setTimeout(() => this.isTokenCopied = !this.isTokenCopied, 3000);
        }


    }
}
