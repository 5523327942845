import { Scenario } from './scenario.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ScenarioService {

    private scenarios$ = new BehaviorSubject<Scenario[]>(null);
    scenarios = this.scenarios$.asObservable();


    constructor(private http: HttpClient) {

    }

    getScenarios(): void {

        this.http.get('../../assets/data/scenarios.json').
            pipe(map((data: any) => data as Scenario[]))
            .subscribe(result => {
                this.scenarios$.next(result);
            });

    }

    generateToken(): string {
        return Math.random().toString(36).substring(3).toUpperCase();
    }


}
