<div class="flex h-screen w-screen" *ngIf="selectedScenario">
  <!-- Sidebar -->
  <div class="w-20 bg-main p-4 h-full flex flex-col justify-between">
    <div class="flex flex-col">

      <i class="fa fa-3x fa-sun-o text-yellow-700"></i>

      <div class="sidebar_menu mt-10">

        <div class="selected rounded-l-2xl bg-side items-center w-16 pb-10 pt-1 h-16">
          <div class="rounded-full mb-2 w-14 h-14 pt-2 bg-purple-900 white text-center">
            <i class="fa text-3xl fa-comment"></i>
          </div>
        </div>

        <div class="items-center w-16 pb-10 pt-1 h-16 mt-4">
          <div class="mb-2 w-14 h-14 pt-2 text-gray-700 text-center">
            <i class="fa text-3xl fa-user"></i>
          </div>
        </div>


      </div>

    </div>

    <div class="rounded w-12 h-12 text-center rounded-full white text-center">
      <img alt="tmobile-logo" src="../../assets/images/t-mobile-icon.png"/>
    </div>

  </div>

  <!-- Main Window -->
  <div class="flex w-full h-full bg-main">

    <div class="flex flex-col w-full">

      <!-- Text -->
      <div class="flex w-full pb-2">
        <div class="flex">
          <div class="pl-8 mt-2 flex flex-col border-l border-gray-700 h-14">
            <h1 class="text-2xl">Agent Workspace</h1>
            <p class="text-gray-300">My Conversations</p>
          </div>

          <h1 class="p-2 ml-4 text-gray-600 items-center text-4xl">&rsaquo;</h1>
        </div>

        <div class="flex ml-4 flex-col w-auto mt-2 border-r pr-4 border-gray-600">
          <h1 class="text-xl text-left ">1</h1>
          <p class="text-sm text-gray-500 text-left ">OPEN</p>
        </div>

        <div class="flex ml-4 flex-col w-auto mt-2 border-r pr-4 border-gray-600">
          <h1 class="text-xl text-left ">1</h1>
          <p class="text-sm text-gray-500 text-left ">PENDING</p>
        </div>

        <div class="flex ml-4 flex-col w-auto mt-2 border-r pr-4 border-gray-600">
          <h1 class="text-xl text-left ">0</h1>
          <p class="text-sm text-gray-500 text-left ">OVERDUE</p>
        </div>

        <div class="flex ml-4 flex-col w-auto mt-2 border-r pr-4 border-gray-600">
          <h1 class="text-xl text-left ">1</h1>
          <p class="text-sm text-gray-500 text-left ">SOON TO BE OVERDUE</p>
        </div>

        <div class="flex ml-4 flex-col w-auto mt-2 border-r pr-4 border-gray-600">
          <h1 class="text-xl text-left ">0%</h1>
          <p class="text-sm text-gray-500 text-left ">CSAT</p>
        </div>

        <div class="flex ml-4 flex-col w-auto mt-2 border-r pr-4 border-gray-600">
          <span class="rounded-sm w-4 h-4 my-4 mx-2 text-white text-center border border-gray-500"></span>

        </div>

      </div>

      <!-- Router Outlet -->
      <div class="flex rounded-l-xl w-full h-full bg-side">
        <div class="flex flex-col justify-start pb-10 w-full max-h-full">
          <!-- Breadcrumbs -->
          <div class="flex w-full p-8 justify-start">
            <a href="#" class="text-blue-300 font-bold underline mr-2">My Conversations</a>
            |
            <a href="#" class="text-white ml-2">All Conversations</a>
          </div>

          <div class="flex w-full max-h-full">

            <!-- Chat list -->
            <div class="w-1/6 ml-8 bg-bubble-left h-full items-center rounded-2xl pb-2">
              <!-- Filter Controls -->
              <div class="circle__icons mb-2 w-full flex justify-between pt-2 text-md px-4">

                <div class="text-lg">
                  <span class="px-1 border-r border-gray-700">
                    <i class="fa fa-bell-o text-pink-700"></i> 0
                  </span>

                  <span class="px-2 border-r border-gray-700">
                    <i class="fa fa-comment-o text-green-700"></i> 0
                  </span>

                  <span class="px-2 border-r border-gray-700">
                    <i class="fa fa-moon-o text-blue-700"></i> 0
                  </span>

                </div>

                <div class="text-xl">
                  <i class="fa fa-filter text-blue-300"></i>
                </div>
              </div>

              <div class="flex w-full h-full">

                <div
                  class="conversation-item bg-gray-100 bg-opacity-20 h-20 w-full border-t border-b border-gray-600 p-4">
                  <div class="flex w-full justify-between">

                    <span class="rounded mr-2 w-12 h-10 py-1.5 bg-blue-800 rounded-full text-white text-center">
                      <i class="fa fa-cloud"></i>
                    </span>

                    <div class="flex w-full flex-col">
                      <div class="w-full flex justify-between">
                        <span>{{ selectedScenario.customer.name }} <i class="fa fa-globe"></i></span>
                        <i class="fa mr-1 fa-comment mt-1 text-green-700"></i>
                      </div>

                      <div class="w-full text-sm text-gray-400 flex justify-between -mt-0.5">
                        <span></span>
                        <p class="text-green-500">1m</p>
                      </div>

                    </div>


                  </div>
                </div>


              </div>
            </div>

            <!-- Realplay BOT goes here --->
            <div class="w-2/6 ml-4 flex flex-col justify-between h-full">

              <iframe class="h-full" [src]="rpURLsafe"></iframe>

            </div>

            <!-- Articles Here -->
            <div class="w-3/6 ml-6 flex flex-col justify-between h-full bg-side">
              <div class="flex w-full">
                <div class="circle__icons flex text-sm">
                  <span
                    class="rounded mr-2 w-8 h-8 py-1 rounded-full bg-purple-900 text-green-400 text-center">CI</span>
                  <span
                    class="rounded mr-2 w-8 h-8 py-1 rounded-full bg-purple-900 text-green-400 text-center">IN</span>
                  <span
                    class="rounded mr-2 w-8 h-8 py-1 border border-gray-700 rounded-full text-white text-center">HI</span>
                  <span
                    class="rounded mr-2 w-8 h-8 py-1 border border-gray-700 rounded-full text-white text-center">EA</span>
                  <span
                    class="rounded mr-2 w-8 h-8 py-1 border border-gray-700 rounded-full text-white text-center">AM</span>
                  <span class="rounded mr-2 w-8 h-8 py-1 border border-gray-700 rounded-full text-white text-center">
                    <i class="fa fa-book"></i>
                  </span>
                  <span class="rounded mr-2 w-8 h-8 py-1 border border-gray-700 rounded-full text-white text-center">
                    <i class="fa fa-edit"></i>
                  </span>
                  <span class="rounded mr-2 w-8 h-8 py-1 border border-gray-700 rounded-full text-white text-center">
                    <i class="fa fa-clipboard"></i>
                  </span>
                  <span class="rounded mr-2 w-8 h-8 py-1 border border-gray-700 rounded-full text-white text-center">
                    <i class="fa fa-compass"></i>
                  </span>
                  <span class="rounded mr-2 w-8 h-8 py-1 border border-gray-700 rounded-full text-white text-center">
                    <i class="fa fa-refresh"></i>
                  </span>
                  <span class="rounded mr-2 w-8 h-8 py-1 border border-gray-700 rounded-full text-white text-center">
                    <i class="fa fa-id-card"></i>
                  </span>
                </div>
              </div>

              <div class="flex flex-col w-3/4 mt-2 h-full pt-2">
                <!-- Purple Header -->
                <div class="rounded-3xl w-full bg-white">
                  <div
                    class="header-purple text-lg w-full align-middle h-12 rounded-t-3xl px-4 py-3 flex justify-between">
                    <div class="ml-2 flex">
                      <div
                        class="rounded-sm text-sm mr-2 w-6 h-6 border rounded border-white bg-purple-900 text-green-400 text-center">
                        CI
                      </div>
                      <div class="-mt-0.5">CI</div>

                    </div>

                    <i class="fa fa-refresh text-white text-lg mr-4"></i>
                  </div>

                </div>

                <!-- User Header -->
                <div class="w-full flex justify-between h-14 bg-white bg-gray-100">
                  <div class="w-1/3 flex">
                    <img src="./assets/images/1.png" alt="shield" class="shield-icon">
                    <span class="text-gray-700 tracking-tighter ml-2 mt-2 font-light text-xl">
                    {{ selectedScenario.customer.name }}
                  </span>
                  </div>

                  <div class="w-1/4 flex">
                    <div class="text-pink-700 mt-2 font-bold">[STA]</div>
                    <div class="flex text-gray-400 ml-2 -space-y-2 flex-col">
                      <span [class.text-xs]="selectedScenario.customer.account.profile.contact.length > 1"
                            [ngClass]="{'text-sm mt-3': selectedScenario.customer.account.profile.contact.length === 1 }"
                            *ngFor="let contact of selectedScenario.customer.account.profile.contact">
                        {{ contact.number}}
                      </span>
                    </div>
                  </div>
                  <div class="p-2 text-gray-500 flex w-2/4">
                    <p class="mt-1 font-bold text-sm">Topic:</p>
                    <div class="text-sm font-bold text-pink-700 w-full mt-1 ml-2">{{ selectedScenario.scenarioTitle }}</div>
                  </div>

                </div>

                <!-- Quadrant -->
                <div class="w-full flex h-full bg-white">
                  <!-- Quadrant Sidebar -->
                  <div class="w-16 h-full flex flex-col justify-between text-black text-center">
                    <div>
                      <div class="w-full h-12 text-center py-2 border-l-4 border-pink-700 sidebar-icon">
                        <img src="./assets/images/2.png" alt="star">
                      </div>

                      <div class="w-full h-12 text-gray-500 text-center py-2 border-t border-gray-200 sidebar-icon">
                        <img src="./assets/images/3.png" alt="user">
                      </div>

                      <div class="w-full h-12 text-gray-500 text-center py-2 border-t border-gray-200 sidebar-icon">
                        <img src="./assets/images/4.png" alt="notes">
                      </div>

                      <div class="w-full h-12 text-gray-500 text-center py-2 border-t border-gray-200 sidebar-icon">
                        <img src="./assets/images/5.png" alt="refresh">
                      </div>
                    </div>

                    <div>
                      <div class="w-full h-12 text-gray-500 text-center py-2 border-t border-gray-200">
                        <i class="fa fa-exclamation-triangle fa-2x"></i>
                      </div>

                      <div (click)="copyToken();"
                           class="cursor-pointer w-full h-12 sidebar-icon text-center py-2 border-t border-gray-200">
                        <img src="./assets/images/6.png" alt="shield">
                      </div>

                      <div class="w-full h-12 text-gray-500 text-center py-2 border-t border-gray-200">
                        <i class="fa fa-gear fa-2x"></i>
                      </div>

                    </div>

                  </div>

                  <div class="flex w-full h-full">

                    <!-- Responses and Atlas Memo Section -->
                    <div class="w-1/2 h-full border border-gray-100 flex flex-col">

                      <!-- Responses -->
                      <div class="w-full flex flex-col bg-gray-50 h-1/2">
                        <div class="flex h-1/2 w-full justify-between h-10">
                          <p class="text-gray-500 p-2 text-sm font-bold">Responses:</p>
                          <span class=" text-gray-500 p-2">
                            <i class="fa fa-search"></i>
                            <i class="fa fa-edit ml-2"></i>
                          </span>
                        </div>

                        <div class="w-full h-full flex-row justify-start">
                          <img class="speech-bubble" alt="speech-bubble" src="../../assets/images/7.png"/>

                          <div class="rounded-xl border
                            text-gray-500 tracking-tighter
                            border-gray-400 text-xs uppercase font-bold
                            text-center w-2/3 h-6 pt-1 mx-auto">
                            <i class="fa fa-edit mr-1"></i>Manage Responses
                          </div>

                        </div>

                      </div>
                      <!-- Atlas Memo -->
                      <div class="w-full h-1/2 flex flex-col border-t border-gray-100">
                        <div class="flex h-10 w-full justify-between h-10">
                          <p class="text-gray-500 p-2 text-sm font-bold">Atlas Memo</p>
                          <span class=" text-gray-500 p-2">
                            <i class="fa fa-arrows-alt ml-2"></i>
                          </span>
                        </div>

                        <p class="text-gray-300 p-2 -mt-3 text-sm">Type to add...</p>

                      </div>

                    </div>

                    <!-- Quick Facts Section -->
                    <div class="w-1/2 flex text-black h-full bg-gray-50 border border-gray-100">

                      <div class="flex flex-col w-full h-full">
                        <!-- Quick Facts Header -->
                        <div class="w-full justify-between h-10">
                          <p class="text-gray-500 p-2 text-sm font-bold">Quick Facts</p>
                        </div>
                        <div
                          class="text-gray-500 tracking-tight border-r-4 border-pink-300 flex flex-col text-sm p-2 w-full h-auto">
                          <div class="flex justify-between">
                            <p>Account Balance</p>
                            <p>${{selectedScenario.customer.account.billing.currentAccountBalance}}</p>
                          </div>
                          <div class="flex justify-between">
                            <p>Due Date</p>
                            <p>{{selectedScenario.customer.account.billing.nextDueDate}}</p>
                          </div>
                          <div class="flex justify-between">
                            <p>Last Pmt Amount</p>
                            <p>N/A</p>
                          </div>
                          <div class="flex justify-between">
                            <p>Last Pmt Date</p>
                            <p>N/A</p>
                          </div>
                          <div class="flex justify-between">
                            <p>Past Due Amount</p>
                            <p>${{selectedScenario.customer.account.billing.amountPastDue}}</p>
                          </div>
                          <div class="flex justify-between">
                            <p>Current Rate Plan</p>
                            <p>T-Mobile ONE TE (LTULFM2)</p>
                          </div>
                        </div>
                        <hr/>
                        <div class="w-full flex p-2 justify-between h-10">
                          <p class="text-gray-500 text-sm font-bold">Articles</p>
                          <i class="fa fa-search"></i>
                        </div>

                        <div class="w-full flex h-72 flex-col justify-between overflow-y-auto">
                          <div class="text-gray-500 w-auto mx-2 mb-4 border border-gray-300 rounded h-auto">
                            <div class="w-full flex justify-between h-auto">
                              <p class="text-gray-500 p-2 text-sm font-bold">Test Drive</p>
                              <i class="fa fa-circle-o p-2"></i>
                            </div>

                            <div
                              class="w-11/12 flex justify-between bg-white mx-auto mb-4 border-gray-300 border rounded h-auto">
                              <p class="text-gray-500 p-2 text-sm">Test Drive</p>
                              <i class="fa fa-upload p-2 text-pink-500"></i>
                            </div>

                          </div>

                          <div class="text-gray-500 mb-4 w-auto mx-2 border border-gray-300 rounded h-auto">
                            <div class="w-full flex justify-between h-auto">
                              <p class="text-gray-500 p-2 text-sm font-bold">Senior Expert Promotion Program</p>
                              <i class="fa fa-circle-o p-2"></i>
                            </div>
                          </div>

                          <div class="text-gray-500 w-auto mb-4 mx-2 border border-gray-300 rounded h-auto">
                            <div class="w-full flex justify-between h-auto">
                              <p class="text-gray-500 p-2 text-sm font-bold">Natural Disaster & sever weather call
                                handling</p>
                              <i class="fa fa-circle-o p-2"></i>
                            </div>
                          </div>

                          <div class="text-gray-500 w-auto mx-2 mb-4 border border-gray-300 rounded h-auto">
                            <div class="w-full flex justify-between h-auto">
                              <p class="text-gray-500 p-2 text-sm font-bold">Pay your bill: Self-Service</p>
                              <i class="fa fa-circle-o p-2"></i>
                            </div>

                            <div
                              class="w-11/12 flex justify-between bg-white mx-auto mb-4 border-gray-300 border rounded h-auto">
                              <p class="text-gray-500 p-2 text-sm">Pay your Bill</p>
                              <i class="fa fa-upload p-2 text-pink-500"></i>
                            </div>

                          </div>

                          <div class="text-gray-500 w-auto mx-2 mb-4 border border-gray-300 rounded h-auto">
                            <div class="w-full flex justify-between h-10">
                              <p class="text-gray-500 p-2 text-sm font-bold">4G LTE CellSpot setup & help</p>
                              <i class="fa fa-circle-o p-2"></i>
                            </div>

                            <div
                              class="w-11/12 flex justify-between bg-white mx-auto mb-4 border-gray-300 border rounded h-auto">
                              <p class="text-gray-500 p-2 text-sm">4G LTE CellSpot setup & help</p>
                              <i class="fa fa-upload p-2 text-pink-500"></i>
                            </div>

                          </div>


                        </div>

                      </div>


                    </div>

                  </div>
                </div>

                <!-- Gray Footer -->
                <div class="rounded-b-md w-full footer">
                  <div class="text-lg w-full align-middle h-12 px-4 py-3 flex justify-between">
                    <div class="w-10 -mt-3 -ml-2 h-10 text-gray-500 border-t border-gray-200 sidebar-icon">
                      <img src="./assets/images/pwr_by.png" class="h-48" alt="pwr">
                    </div>

                  </div>

                </div>

              </div>


            </div>

          </div>

        </div>

      </div>
    </div>
  </div>

</div>


<!--Toast-->
<div *ngIf="isTokenCopied" (click)="isTokenCopied = !isTokenCopied"
     class="shadow alert-toast fixed bottom-0 right-0 m-8 w-5/6 md:w-full max-w-sm">
  <input type="checkbox" class="hidden" id="footertoast">

  <label
    class="close cursor-pointer flex items-start justify-between w-full p-2 bg-main h-auto rounded shadow-lg text-white"
    title="close" for="footertoast">
    Token copied to clipboard.
    <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path
        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
      </path>
    </svg>
  </label>
</div>
