import { ScenarioComponent } from './scenario.component';
import { NgModule } from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ScenarioComponent],
    imports: [HttpClientModule, CommonModule],
    exports: [ScenarioComponent]
})

export class ScenarioModule {

}