import { RouterModule } from '@angular/router';
import { ScenarioModule } from './scenario/scenario.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    ClipboardModule,
    ScenarioModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
